import * as React from "react"

import Hero from "../components/hero"

const AboutPage = () => {
    return (
        <Hero colour={"info"}>
            <div className="content is-medium has-text-centered">
                <div className="title is-size-2-desktop">
                    BURTON PEAKE
                </div>
                <p>
                    A web app development and data science consultancy based in Charlbury, in the West Oxfordshire Cotswolds.
                </p>
                <p>
                    We are two-strong team, working with startups and SMEs locally and internationally.
                    We offer a range of services from advising on data and product strategy through to developing your dashboards, reports and software.
                </p>
            </div>
        </Hero>
    )
}

export default AboutPage
